export const OrganicStatus = {
	conventional: 1,
	organic: 2,
	transitional: 3,
};

// Deprecated! Use TaskType from shared/Models instead!
export const TaskTypes = {
	General: 0,
	Thinning: 1,
	Weeding: 2,
	Fleecing: 3,
	BedShaping: 4,
	Planting: 5,
	Transplanting: 6,
	Irrigate: 7,
	SoilSample: 8,
	Chemical: 9,
};

export const WeedingJobTool = {
	Hand: 1,
	Hoe: 2,
};

export const WeedPressure = {
	Low: 1,
	Moderate: 2,
	High: 3,
};

export const TaskRequestObservation = {
	Other: 0,
	Tape: 1,
	"Doubles and Weeds": 2,
	"Edges & Ovals": 3,
};

export const TaskRequestStatus = {
	Pending: 1,
	ApprovedWithoutModifications: 2,
	ApprovedWithModifications: 3,
	Denied: 4,
	Canceled: 5,
};

export const BedDirections = {
	0: "S->N",
	90: "W->E",
	180: "N->S",
	270: "E->W",
};

// TEMPORARY - to be removed once customer preferences have been reworked.
export const HarvestTypeMapping = {
	"Celery-Fresh Cut": "Fresh Cut",
	// Add other mappings as needed
};
