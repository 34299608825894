/**
 * Simple utility class for tracking the number of pending queries based
 * on some key.
 * See ITasks put mutation for an example usage.
 */
export class QueryTracker {
    constructor() {
        this.state = {};
    }
    start(key) {
        this.state[key] = (this.state[key] || 0) + 1;
    }
    end(key) {
        this.state[key] = (this.state[key] || 1) - 1;
        if (this.state[key] === 0)
            delete this.state[key];
    }
    isPending(key) {
        return this.state[key] > 0;
    }
}
