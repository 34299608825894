import { ingestAPIObject } from "../utils/api";
import { TaskType } from "./TaskType";
export class VirtualTaskIdentifier {
    constructor(data) {
        this.taskType = TaskType.General;
        ingestAPIObject(this, data);
    }
    get idString() {
        return this.virtualId;
    }
}
