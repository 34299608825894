import { ingestAPIObject } from "../utils/api";
export var TaskStatus;
(function (TaskStatus) {
    TaskStatus[TaskStatus["Planned"] = 1] = "Planned";
    TaskStatus[TaskStatus["Completed"] = 2] = "Completed";
    TaskStatus[TaskStatus["InProgress"] = 3] = "InProgress";
    TaskStatus[TaskStatus["Collected"] = 4] = "Collected";
    TaskStatus[TaskStatus["Paused"] = 5] = "Paused";
})(TaskStatus || (TaskStatus = {}));
class TaskStatusExtension {
    static values() {
        return Object.values(TaskStatus).filter((x) => typeof x === "number");
    }
    constructor(data) {
        ingestAPIObject(this, data);
    }
}
TaskStatusExtension.Planned = new TaskStatusExtension({
    taskStatus: TaskStatus.Planned,
    label: "Planned",
    color: "--blue-500",
    icon: "fa-solid fa-calendar",
    tname: "task.status.planned",
});
TaskStatusExtension.Completed = new TaskStatusExtension({
    taskStatus: TaskStatus.Completed,
    label: "Completed",
    color: "--green-600",
    icon: "fa-solid fa-check",
    tname: "task.status.completed",
});
TaskStatusExtension.InProgress = new TaskStatusExtension({
    taskStatus: TaskStatus.InProgress,
    label: "In Progress",
    color: "--blue-500",
    icon: "fa-solid fa-play",
    tname: "task.status.in-progress",
});
TaskStatusExtension.Collected = new TaskStatusExtension({
    taskStatus: TaskStatus.Collected,
    label: "Collected",
    color: "--purple-500",
    icon: "fa-solid fa-vial-circle-check",
    tname: "task.status.collected",
});
TaskStatusExtension.Paused = new TaskStatusExtension({
    taskStatus: TaskStatus.Paused,
    label: "Paused",
    color: "--yellow-400",
    icon: "fa-solid fa-pause",
    tname: "task.status.paused",
});
TaskStatusExtension.TaskStatusMap = {
    [TaskStatus.Planned]: TaskStatusExtension.Planned,
    [TaskStatus.Completed]: TaskStatusExtension.Completed,
    [TaskStatus.InProgress]: TaskStatusExtension.InProgress,
    [TaskStatus.Collected]: TaskStatusExtension.Collected,
    [TaskStatus.Paused]: TaskStatusExtension.Paused,
};
TaskStatusExtension.get = (status) => TaskStatusExtension.TaskStatusMap[status];
// eslint-disable-next-line @typescript-eslint/no-namespace
(function (TaskStatus) {
    TaskStatus.data = (status) => TaskStatusExtension.get(status);
    TaskStatus.all = TaskStatusExtension.values();
    TaskStatus.allData = TaskStatusExtension.values().map((value) => TaskStatusExtension.get(value));
})(TaskStatus || (TaskStatus = {}));
