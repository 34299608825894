import { useAuthorizedQuery } from "../api/authorized-query";
import { seedgreenAPI } from "../api/axios";
import { ChemicalTask } from "../models/ChemicalTask";
import { IrrigationTask } from "../models/IrrigationTask";
import { SoilSampleTask } from "../models/SoilSampleTask";
import { Task, TaskTypeToScheduleType } from "../models/Task";
import { TaskIdentifier } from "../models/TaskIdentifier";
import { TaskType } from "../models/TaskType";
import { VirtualTaskIdentifier } from "../models/VirtualTaskIdentifier";
import { MonthlyPageParam } from "../types/query.types";
import { QueryTracker } from "../utils/query";
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import _ from "lodash";
import { computed } from "vue";
const TASKS_STALE_TIME = 10 * 60 * 1000;
const SINGLE_TASK_STALE_TIME = 5 * 60 * 1000;
export function useITasksMonthly(param, enabled) {
    const queryClient = useQueryClient();
    const tokenValid = useAuthorizedQuery();
    const { data: tasks, isLoading, isFetching, isError, error, refetch, isRefetching, } = useQuery({
        enabled: computed(() => tokenValid.value && (enabled?.value ?? true)),
        queryKey: ["itasks", param],
        queryFn: () => fetchITasksMonthly(param.value),
        staleTime: TASKS_STALE_TIME,
        select: (data) => {
            try {
                return data.map((t) => {
                    if (t.type === TaskType.Irrigation)
                        return new IrrigationTask(t);
                    else if (t.type === TaskType.SoilSample)
                        return new SoilSampleTask(t);
                    else if (t.type === TaskType.Chemical)
                        return new ChemicalTask(t);
                    return new Task(t);
                });
            }
            catch (error) {
                console.error("query/itasks::select ERROR", error);
                throw error;
            }
        },
    });
    async function prefetchTasks(params) {
        if (!tokenValid.value)
            return;
        try {
            await queryClient.prefetchQuery({
                queryKey: ["itasks", params],
                queryFn: () => fetchITasksMonthly(params),
                staleTime: TASKS_STALE_TIME,
            });
        }
        catch (error) {
            console.error("query/itasks::prefetch ERROR", error);
            throw error;
        }
    }
    return {
        tasks,
        isLoading,
        isFetching,
        isError,
        error,
        refetch,
        isRefetching,
        prefetchTasks,
    };
}
async function fetchITasksMonthly(param) {
    const response = await seedgreenAPI.get("/ITask", {
        params: {
            start: param.startDateStr,
            end: param.endDateStr,
        },
    });
    return response.data;
}
export function useITasksByDateRange(param, enabled) {
    const queryClient = useQueryClient();
    const tokenValid = useAuthorizedQuery();
    const query = useQuery({
        enabled: computed(() => tokenValid.value && (enabled?.value ?? true)),
        queryKey: ["itasks", { type: "dateRange", ...param.value }],
        queryFn: () => fetchITasksByDateRange(param.value.startDate, param.value.endDate),
        staleTime: TASKS_STALE_TIME,
        select: (data) => {
            try {
                return data.map((t) => {
                    if (t.type === TaskType.Irrigation)
                        return new IrrigationTask(t);
                    else if (t.type === TaskType.SoilSample)
                        return new SoilSampleTask(t);
                    else if (t.type === TaskType.Chemical)
                        return new ChemicalTask(t);
                    return new Task(t);
                });
            }
            catch (error) {
                console.error("query/itasks::select ERROR", error);
                throw error;
            }
        },
    });
    async function prefetchTasks(params) {
        if (!tokenValid.value)
            return;
        try {
            await queryClient.prefetchQuery({
                queryKey: ["itasks", { type: "dateRange", ...params }],
                queryFn: () => fetchITasksByDateRange(params.startDate, params.endDate),
                staleTime: TASKS_STALE_TIME,
            });
        }
        catch (error) {
            console.error("query/itasks::prefetch ERROR", error);
            throw error;
        }
    }
    return {
        ...query,
        prefetchTasks,
    };
}
async function fetchITasksByDateRange(startDate, endDate) {
    const response = await seedgreenAPI.get("/ITask", {
        params: {
            start: startDate,
            end: endDate,
        },
    });
    return response.data;
}
export function useITask(identifier) {
    const queryClient = useQueryClient();
    const query = useQuery({
        enabled: useAuthorizedQuery(),
        queryKey: ["itask", identifier],
        queryFn: async () => {
            if (identifier.value === undefined)
                return null;
            let params, endpoint;
            if (identifier.value instanceof TaskIdentifier) {
                if (identifier.value.taskType === TaskType.Irrigation)
                    endpoint = "/Irrigation";
                else if (identifier.value.taskType === TaskType.SoilSample)
                    endpoint = "/SoilSample";
                else if (identifier.value.taskType === TaskType.Chemical)
                    endpoint = "/Chemical";
                else
                    endpoint = "/Task";
                params = { taskId: identifier.value.rawId };
            }
            else if (identifier.value instanceof VirtualTaskIdentifier) {
                if (identifier.value.taskType === TaskType.Irrigation)
                    endpoint = "/Irrigation/Virtual";
                else if (identifier.value.taskType === TaskType.SoilSample)
                    endpoint = "/SoilSample/Virtual";
                else if (identifier.value.taskType === TaskType.Chemical)
                    endpoint = "/Chemical/Virtual";
                else
                    endpoint = "/Task/Virtual";
                params = { ...identifier.value };
            }
            if (!endpoint)
                return null;
            const { data } = await seedgreenAPI.get(endpoint, { params });
            return data;
        },
        staleTime: SINGLE_TASK_STALE_TIME,
        select: (data) => {
            if (!data)
                return;
            try {
                if (data.type === TaskType.Irrigation)
                    return new IrrigationTask(data);
                else if (data.type === TaskType.SoilSample)
                    return new SoilSampleTask(data);
                else if (data.type === TaskType.Chemical)
                    return new ChemicalTask(data);
                return new Task(data);
            }
            catch (error) {
                console.error("query/itask::select ERROR", error);
                throw error;
            }
        },
        initialData: () => {
            if (identifier.value === undefined)
                return null;
            const data = queryClient.getQueriesData({
                queryKey: ["itasks"],
            });
            const tasks = _.flatMap(data?.map((tuple) => tuple[1]) ?? []).filter((x) => x !== undefined);
            // Typescript can't figure this out
            if (identifier.value instanceof TaskIdentifier) {
                return tasks.find((t) => t.id == identifier.value.rawId && t.type === identifier.value?.taskType);
            }
            else if (identifier.value instanceof VirtualTaskIdentifier) {
                return tasks.find((t) => t.virtualId == identifier.value.virtualId &&
                    t.type === identifier.value?.taskType);
            }
        },
    });
    return {
        task: query.data,
        ...query,
    };
}
export function usePutITask(optimistic = true) {
    const queryClient = useQueryClient();
    const mutation = useMutation(putITaskOptions(queryClient, optimistic));
    return {
        putITask: mutation.mutate,
        putITaskAsync: mutation.mutateAsync,
        ...mutation,
    };
}
const putTracker = new QueryTracker();
export function putITaskOptions(queryClient, optimistic) {
    return {
        mutationFn: async (data) => {
            putTracker.start(data.task.identifier?.idString ?? "");
            if (data.task.type === TaskType.Irrigation) {
                const response = await seedgreenAPI.put("/Irrigation", { ...data.task });
                return new IrrigationTask(response.data);
            }
            else if (data.task.type === TaskType.SoilSample) {
                const response = await seedgreenAPI.put("/SoilSample", { ...data.task });
                return new SoilSampleTask(response.data);
            }
            else if (data.task.type === TaskType.Chemical) {
                const response = await seedgreenAPI.put("/Chemical", { ...data.task });
                return new ChemicalTask(response.data);
            }
            else {
                const response = await seedgreenAPI.put("/Task", { ...data.task });
                return new Task(response.data);
            }
        },
        onMutate: async (data) => {
            await queryClient.cancelQueries({ queryKey: ["itask", data.task.identifier] });
            if (data.oldTask)
                await queryClient.cancelQueries({ queryKey: ["itask", data.oldTask?.identifier] });
            const context = {
                tasks: queryClient.getQueryData(["itasks"]), // TODO: We save this for restore, but might be better/faster to just invalidate it on failure
                task: data.oldTask ? queryClient.getQueryData(["itask", data.oldTask.identifier]) : undefined,
                oldTaskIdf: data.oldTask?.identifier,
                taskIdf: data.task.identifier,
            };
            if (optimistic) {
                updateCaches(data);
            }
            return context;
        },
        onError: async (error, variables, context) => {
            console.error("query/itasks/put::onError", error);
            putTracker.end(variables.task.identifier?.idString ?? "");
            if (context) {
                await queryClient.setQueryData(["itasks"], context.tasks);
                if (context.taskIdf)
                    await queryClient.setQueryData(["itask", context.taskIdf], null);
                if (context.oldTaskIdf)
                    await queryClient.setQueryData(["itask", context.oldTaskIdf], context.task);
                queryClient.invalidateQueries({ queryKey: ["itask", context.taskIdf] });
            }
            queryClient.invalidateQueries({ queryKey: ["itasks"] });
        },
        onSuccess: (response, data, _context) => {
            putTracker.end(data.task.identifier?.idString ?? "");
            if (optimistic)
                data.oldTask ?? (data.oldTask = data.task); // We've already updated the cache once
            data.task = response;
            // Only update from final response recieved
            if (!putTracker.isPending(data.task.identifier?.idString ?? "")) {
                updateCaches(data);
            }
        },
    };
    function updateCaches(data) {
        updateITasksMonthlyCache(data);
        updateITasksByDateRangeCache(data);
        updateITaskCache(data);
    }
    function updateITaskCache(data) {
        // Delete old cache entry if identifier changed
        const identifierChanged = !_.isEqual(data.task.identifier, data.oldTask?.identifier);
        const dataAtOldTaskIdentifier = data.oldTask && queryClient.getQueryState(["itask", data.oldTask.identifier]);
        if (identifierChanged && dataAtOldTaskIdentifier)
            queryClient.setQueryData(["itask", data.oldTask.identifier], undefined);
        queryClient.setQueryData(["itask", data.task.identifier], data.task);
    }
    function updateITasksMonthlyCache(data) {
        // Delete the old task
        if (data.oldTask) {
            const oldTask = data.oldTask;
            if (!oldTask.displayDate)
                throw Error("query/itasks/put::updateMonthlyCache ERROR: Old task has no displayDate");
            const oldPage = MonthlyPageParam.fromDateString(oldTask.displayDate);
            const oldPageState = queryClient.getQueryState(["itasks", oldPage]);
            if (oldPageState) {
                queryClient.setQueryData(["itasks", oldPage], (old) => {
                    if (oldTask.virtualId) {
                        return old.filter((t) => t.virtualId !== oldTask.virtualId);
                    }
                    else if (oldTask.id) {
                        return old.filter((t) => !(t.id === oldTask.id && t.type === oldTask.type));
                    }
                });
            }
        }
        // Add the updated task
        if (!data.task.displayDate)
            throw Error("query/itasks/put::updateMonthlyCache ERROR: Task has no displayDate");
        const page = MonthlyPageParam.fromDateString(data.task.displayDate);
        const pageState = queryClient.getQueryState(["itasks", page]);
        if (pageState) {
            queryClient.setQueryData(["itasks", page], (old) => [...old, data.task]);
        }
    }
    function updateITasksByDateRangeCache(data) {
        if (!data.task.displayDate)
            throw Error("query/itasks/put::updateMonthlyCache ERROR: Task has no displayDate");
        const pages = queryClient.getQueriesData({ queryKey: ["itasks"] });
        pages.forEach(([key]) => {
            const params = key[1];
            if (!params || !params.startDate || !params.endDate || params.type !== "dateRange")
                return;
            // Check if this page should contain the task
            const shouldContainTask = params.startDate <= data.task.displayDate && params.endDate >= data.task.displayDate;
            const containsOldTask = data.oldTask &&
                params.startDate <= data.oldTask.displayDate &&
                params.endDate >= data.oldTask.displayDate;
            if (!shouldContainTask && !containsOldTask)
                return;
            queryClient.setQueryData(key, (old) => {
                // First, remove the old task if it exists
                const filteredTasks = data.oldTask
                    ? old.filter((t) => {
                        if (data.oldTask.virtualId && t.virtualId === data.oldTask.virtualId) {
                            return false;
                        }
                        if (data.oldTask.id && t.id === data.oldTask.id)
                            return false;
                        return true;
                    })
                    : [...old];
                // If this page should contain the updated task, insert it at the same index
                // where the old task was, or at the end if it's new
                if (shouldContainTask) {
                    const oldIndex = old.findIndex((t) => (data.oldTask?.virtualId && t.virtualId === data.oldTask.virtualId) ||
                        (data.oldTask?.id && t.id === data.oldTask.id && t.type === data.oldTask.type));
                    if (oldIndex !== -1) {
                        // Insert at the old position
                        filteredTasks.splice(oldIndex, 0, data.task);
                    }
                    else {
                        // If it's a new task, add it to the end
                        filteredTasks.push(data.task);
                    }
                }
                return filteredTasks;
            });
        });
    }
}
export function useDeleteITask(optimistic = true) {
    const queryClient = useQueryClient();
    const { mutate, mutateAsync, isPending, isError, error, isSuccess } = useMutation({
        mutationFn: async (task) => {
            if (task.identifier instanceof TaskIdentifier && task.identifier.rawId) {
                if (task.type === TaskType.Irrigation)
                    await seedgreenAPI.delete(`/Irrigation/${task.identifier.rawId}`);
                else if (task.type === TaskType.SoilSample)
                    await seedgreenAPI.delete(`/SoilSample/${task.identifier.rawId}`);
                else if (task.type === TaskType.Chemical)
                    await seedgreenAPI.delete(`/Chemical/${task.identifier.rawId}`);
                else
                    await seedgreenAPI.delete(`/Task/${task.identifier.rawId}`);
            }
            else if (task.identifier instanceof VirtualTaskIdentifier && task.identifier.virtualId) {
                if (task.type === TaskType.Irrigation) {
                    await seedgreenAPI.put("/Irrigation/AutoSchedule", {
                        enabled: false,
                        plantingId: task.location?.plantingIds?.[0],
                        irrigationBlockId: task.location?.irrigationBlockId,
                    });
                }
                else if (task.type === TaskType.SoilSample) {
                    await seedgreenAPI.put("/SoilSample/AutoSchedule", {
                        enabled: false,
                        plantingId: task.location?.plantingIds?.[0],
                        irrigationBlockId: task.location?.irrigationBlockId,
                    });
                }
                else if (task.type === TaskType.Chemical) {
                    await seedgreenAPI.put("/Chemical/AutoSchedule", {
                        enabled: false,
                        plantingId: task.location?.plantingIds?.[0],
                        irrigationBlockId: task.location?.irrigationBlockId,
                    });
                }
                else {
                    await seedgreenAPI.put("/Schedule/DisablePlantingScheduling", {
                        plantingId: task.location?.plantingIds?.[0],
                        scheduleType: TaskTypeToScheduleType[task.type],
                    });
                }
            }
            else {
                console.warn(`query/itasks/delete::onMutate ERROR: Could not delete task with identifier: ${task.identifier}`);
            }
            return true;
        },
        onMutate: async (task) => {
            await queryClient.cancelQueries({ queryKey: ["itask", task.identifier] });
            const context = {
                tasks: queryClient.getQueryData(["itasks"]),
                task: queryClient.getQueryData(["itask", task.identifier]),
                // TODO: Save montly cache to context
                taskIdf: task.identifier,
            };
            if (optimistic) {
                updateCaches(task);
            }
            return context;
        },
        onError: async (error, _variables, context) => {
            console.error("query/itasks/delete::onError", error);
            if (context) {
                await queryClient.setQueryData(["itasks"], context.tasks);
                if (context.taskIdf)
                    await queryClient.setQueryData(["itask", context.taskIdf], context.task);
                queryClient.invalidateQueries({ queryKey: ["itask", context.taskIdf] });
            }
            queryClient.invalidateQueries({ queryKey: ["itasks"] });
        },
        onSuccess: (response, task, _context) => {
            if (response && !optimistic)
                updateCaches(task);
        },
    });
    function updateCaches(task) {
        updateITasksMonthlyCache(task);
        updateTaskCache(task);
    }
    function updateTaskCache(task) {
        queryClient.setQueryData(["itask", task.identifier], undefined);
    }
    function updateITasksMonthlyCache(task) {
        if (!task.displayDate)
            throw Error("query/itasks/delete::updateMonthlyCache ERROR: task has no displayDate");
        const taskPage = MonthlyPageParam.fromDateString(task.displayDate);
        const taskPageState = queryClient.getQueryState(["itasks", taskPage]);
        if (taskPageState) {
            queryClient.setQueryData(["itasks", taskPage], (old) => {
                if (task.virtualId) {
                    return old.filter((t) => t.virtualId !== task.virtualId);
                }
                else if (task.id) {
                    return old.filter((t) => !(t.id === task.id && t.type === task.type));
                }
            });
        }
    }
    return {
        deleteITask: mutate,
        deleteITaskAsync: mutateAsync,
        isPending,
        isError,
        error,
        isSuccess,
    };
}
export function useLinkedITasks(task) {
    const queryClient = useQueryClient();
    return {
        linkedTasks: computed(() => {
            if (task.value === undefined)
                return [];
            const scheduleConfigs = queryClient.getQueryData(["schedule-configs"]);
            if (!scheduleConfigs)
                return [];
            const scheduleConfig = scheduleConfigs.find((sc) => sc.taskType === task.value.type);
            if (!scheduleConfig)
                return [];
            const linkedScheduleType = scheduleConfig.linkedScheduleType;
            if (!linkedScheduleType)
                return [];
            const linkedScheduleConfig = scheduleConfigs.find((sc) => sc.scheduleType === linkedScheduleType);
            if (!linkedScheduleConfig)
                return [];
            const linkedTaskType = linkedScheduleConfig?.taskType;
            if (!linkedTaskType)
                return [];
            const taskPlantings = new Set(task.value.location?.plantingIds || []);
            // Build a local cache list of all the tasks we have loaded
            const allTasksPages = queryClient.getQueriesData({
                queryKey: ["itasks"],
            });
            const tasks = _.flatMap(allTasksPages?.map((tuple) => tuple[1]) ?? []).filter((x) => x !== undefined);
            const possibleLinkedTasks = tasks.filter((t) => t.type === linkedTaskType && t.location?.plantingIds?.every((id) => taskPlantings.has(id)));
            return possibleLinkedTasks.map((t) => {
                if (t.type === TaskType.Irrigation)
                    return new IrrigationTask(t);
                else if (t.type === TaskType.SoilSample)
                    return new SoilSampleTask(t);
                else if (t.type === TaskType.Chemical)
                    return new ChemicalTask(t);
                return new Task(t);
            });
            // TODO: We could create an endpoint that queries for linked tasks that might not be loaded in the client
            //    (for example, if the linked task is super far in the future/past). Only if this becomes an issue.
        }),
    };
}
