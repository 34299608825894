import { ingestAPIObject } from "../utils/api";
import { subscriptNumbers } from "../utils/string";
import dayjs from "dayjs";
import { ITask } from "./ITask";
import { TaskStatus } from "./TaskStatus";
import { TaskType } from "./TaskType";
export class ChemicalTask extends ITask {
    get displayDate() {
        return this.completedDate ?? this.inProgressDate ?? this.scheduledDate ?? new Date().toISOString().slice(0, 10);
    }
    set displayDate(date) {
        if (this.inProgressDate || this.completedDate)
            this.inProgressDate = date;
        else
            this.scheduledDate = date;
    }
    get status() {
        if (this.completedDate)
            return TaskStatus.Completed;
        else if (this.inProgressDate)
            return TaskStatus.InProgress;
        return TaskStatus.Planned;
    }
    set status(status) {
        if (status === TaskStatus.Completed && this.loadsHaveBeenGenerated) {
            this.completedDate = new Date().toISOString();
        }
        else if (status === TaskStatus.InProgress && this.loadsHaveBeenGenerated) {
            this.inProgressDate = this.displayDate;
            this.completedDate = undefined;
        }
        else {
            this.completedDate = undefined;
            this.inProgressDate = undefined;
            this.scheduledDate ?? (this.scheduledDate = new Date().toISOString().slice(0, 10));
        }
    }
    get loadsHaveBeenGenerated() {
        return (this.sprayMethod > 0 &&
            (this?.tankSizeInGallons ?? 0) > 0 &&
            (this?.injectionRateInGallonsPerAcre ?? 0) > 0 &&
            (this?.sprayRateInGallonsPerAcre ?? 0) > 0);
    }
    get allowedStatuses() {
        return [TaskStatus.Planned, TaskStatus.InProgress, TaskStatus.Completed];
    }
    constructor(jsonData) {
        super();
        this.type = TaskType.Chemical;
        this.title = "Chemical";
        this.sprayMethod = ChemicalSprayMethod.BROADCAST;
        ingestAPIObject(this, jsonData);
        // Transform dates to datestrings only
        this.scheduledDate = jsonData.scheduledDate?.slice(0, 10);
        this.inProgressDate = jsonData.inProgressDate?.slice(0, 10);
        this.completedDate = jsonData.completedDate?.slice(0, 10);
    }
    get isLocked() {
        // Can't move a completed chemical 24 hours after completion
        return this.status === TaskStatus.Completed && dayjs.utc(this.completedDate).add(24, "hour") < dayjs();
    }
    // TODO: Cache this, and recompute only at reasonable times?
    fcEvent(title, scheduleConfig) {
        const loading = !title;
        return {
            id: this.id || this.virtualId, // TODO: better id handling
            // groupId: TODO: could maybe group linked or batch select?
            title: title || "Loading...",
            allDay: true,
            start: this.displayDate, // FIXME: parse date only when needed (future me: what??)
            end: this.displayDate,
            color: scheduleConfig?.color,
            textColor: scheduleConfig?.fontColor,
            // constraint: check planting(s)
            durationEditable: false,
            startEditable: (!loading && scheduleConfig?.setPermission) || false,
            extendedProps: {
                icon: this.icon,
                iconTitle: this.iconTitle,
                type: this.type,
                status: this.status,
                task: this,
                subtitle: undefined,
            },
        };
    }
}
export var ChemicalSprayMethod;
(function (ChemicalSprayMethod) {
    ChemicalSprayMethod[ChemicalSprayMethod["BROADCAST"] = 1] = "BROADCAST";
    ChemicalSprayMethod[ChemicalSprayMethod["BEDTOP"] = 2] = "BEDTOP";
})(ChemicalSprayMethod || (ChemicalSprayMethod = {}));
export var ChemicalApplicationMethod;
(function (ChemicalApplicationMethod) {
    ChemicalApplicationMethod[ChemicalApplicationMethod["SPRAY"] = 1] = "SPRAY";
    ChemicalApplicationMethod[ChemicalApplicationMethod["INJECT"] = 2] = "INJECT";
})(ChemicalApplicationMethod || (ChemicalApplicationMethod = {}));
// eslint-disable-next-line @typescript-eslint/no-namespace
(function (ChemicalSprayMethod) {
    function data(type) {
        switch (type) {
            case ChemicalSprayMethod.BROADCAST:
                return {
                    label: ChemicalSprayMethodLabels[ChemicalSprayMethod.BROADCAST],
                    htmlLabel: subscriptNumbers(ChemicalSprayMethodLabels[ChemicalSprayMethod.BROADCAST]),
                };
            case ChemicalSprayMethod.BEDTOP:
                return {
                    label: ChemicalSprayMethodLabels[ChemicalSprayMethod.BEDTOP],
                    htmlLabel: subscriptNumbers(ChemicalSprayMethodLabels[ChemicalSprayMethod.BEDTOP]),
                };
        }
    }
    ChemicalSprayMethod.data = data;
})(ChemicalSprayMethod || (ChemicalSprayMethod = {}));
const ChemicalSprayMethodLabels = {
    [ChemicalSprayMethod.BROADCAST]: "Broadcast",
    [ChemicalSprayMethod.BEDTOP]: "Bed Top",
};
export class ChemicalTaskFormula {
    constructor(jsonData) {
        ingestAPIObject(this, jsonData);
    }
}
