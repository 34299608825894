<template>
	<base-page :title="planting.plantingRegion" v-if="planting">
		<template v-slot:header-left-button>
			<!-- Button to view the planting in the Lot Search context -->
			<ion-button
				@click="$router.push({ name: 'lot-search', query: { plantingId } })"
				style="font-size: 20px; margin-right: -16px"
			>
				<ion-icon :icon="searchicon"></ion-icon>
			</ion-button>
		</template>

		<!-- Header tabs -->
		<ion-tabs>
			<ion-router-outlet></ion-router-outlet>
			<ion-tab-bar id="planting-tabs" slot="top" :color="onlineColor">
				<ion-tab-button tab="details" :href="`/plantings/${plantingId}/details`">
					<ion-icon :icon="leaficon"></ion-icon>
					<ion-label>{{ $t("planting-details.details") }}</ion-label>
				</ion-tab-button>

				<ion-tab-button tab="map" :href="`/plantings/${plantingId}/map`">
					<ion-icon :icon="mapicon"></ion-icon>
					<ion-label>{{ $t("location.map") }}</ion-label>
				</ion-tab-button>

				<ion-tab-button v-if="isDudaCelery" tab="forecast" :href="`/plantings/${plantingId}/forecast`">
					<ion-icon :icon="forecasticon"></ion-icon>
					<ion-label>Forecast</ion-label>
				</ion-tab-button>

				<ion-tab-button tab="notes" :href="`/plantings/${plantingId}/notes`">
					<ion-icon :icon="bookicon"></ion-icon>
					<ion-label>{{ $tc("note.note", 2) }}</ion-label>
					<ion-badge color="primary" style="padding: 4px 6px" v-if="plantingNotes && plantingNotes.length">
						{{ plantingNotes.length }}
					</ion-badge>
				</ion-tab-button>

				<ion-tab-button v-if="plantingAlerts.length" tab="alerts" :href="`/plantings/${plantingId}/alerts`">
					<ion-icon :icon="notificationsicon"></ion-icon>
					<ion-label>{{ $tc("common.alert", 2) }}</ion-label>
					<ion-badge color="danger" style="padding: 4px 6px">
						{{ plantingAlerts.length }}
					</ion-badge>
				</ion-tab-button>

				<ion-tab-button tab="tasks" :href="`/plantings/${plantingId}/tasks`" :style="tasksTabButtonStyle">
					<ion-icon :icon="clipboardicon"></ion-icon>
					<ion-label>{{ $tc("task.task", 2) }}</ion-label>
				</ion-tab-button>
			</ion-tab-bar>
		</ion-tabs>

		<template v-slot:footer>
			<!-- Tasks/Requests Footer -->
			<ion-tab-bar
				color="light"
				slot="bottom"
				v-if="currentTab.startsWith('planting.task')"
				style="display: flex !important"
			>
				<ion-tab-button :selected="currentTab === 'planting.tasks'" @click="viewTab('planting.tasks')">
					<ion-label>{{ $tc("task.task", 2) }}</ion-label>
				</ion-tab-button>
				<div style="height: 80%; border-left: 0.5px solid #ccc"></div>
				<ion-tab-button
					:selected="currentTab === 'planting.taskRequests'"
					@click="viewTab('planting.taskRequests')"
				>
					<ion-label>{{ $tc("task.request", 2) }}</ion-label>
					<ion-badge color="warning" v-if="pendingRequests.length">
						{{ pendingRequests.length }}
					</ion-badge>
				</ion-tab-button>
			</ion-tab-bar>
		</template>

		<!-- Add Items FAB -->
		<ion-fab class="fab-wrapper-modal" v-if="currentTab !== 'planting.map'">
			<ion-fab-button>
				<ion-icon :icon="addicon"></ion-icon>
			</ion-fab-button>

			<ion-fab-list side="top">
				<!-- Add note -->
				<ion-fab-button
					class="fab-list-button"
					@click="showNotePopup(planting)"
					color="primary"
					:disabled="!(permissions['edit_any_field_note'] || permissions['create_field_notes'])"
				>
					<i class="fas fa-comment-medical" style="transform: scaleX(-1)"></i>
				</ion-fab-button>
				<!-- Add task request -->
				<ion-fab-button
					class="fab-list-button"
					@click="showTaskRequestPopup(planting)"
					color="primary"
					:disabled="!permissions['create_task_request']"
				>
					<i class="fas fa-notes-medical"></i>
				</ion-fab-button>
			</ion-fab-list>
		</ion-fab>
	</base-page>
</template>

<style>
	/* Wrapper for a fab list in a modal, since they don't work out of the box in a modal*/
	.fab-wrapper-modal {
		position: absolute;
		bottom: 0;
		right: 0;
		margin: 12px;
		border-radius: 50%;
	}

	/* Custom fontawesome fab list buttons */
	.fab-list-button {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 24px;
		width: 48px;
		height: 48px;
		border-radius: 50%;
		z-index: 4;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
		text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
	}

	/* Grey out disabled fab buttons */
	.fab-button-disabled {
		filter: grayscale(0.9);
		opacity: 0.9;
	}
</style>

<script>
	import { mapState, mapGetters } from "vuex";
	import { customerConversion, capitalizeFirstLetter } from "@/utils";
	import { TaskRequestStatus } from "@/constants";
	import { showAddEditNote as showAddNote } from "@/views/Modal/AddEditNote.vue";
	import { showAddEditTaskRequest as showRequestTask } from "@/views/Modal/AddEditTaskRequest.vue";
	import {
		IonButton,
		IonRouterOutlet,
		IonIcon,
		IonLabel,
		IonBadge,
		IonFabButton,
		IonFab,
		IonFabList,
		IonTabButton,
		IonTabBar,
		IonTabs,
	} from "@ionic/vue";
	import {
		search as searchicon,
		leaf as leaficon,
		map as mapicon,
		book as bookicon,
		notifications as notificationsicon,
		clipboard as clipboardicon,
		add as addicon,
		playForwardCircleSharp as forecasticon,
	} from "ionicons/icons";

	export default {
		name: "PlantingPage",
		components: {
			// Ionic Components
			IonButton,
			IonRouterOutlet,
			IonIcon,
			IonLabel,
			IonBadge,

			IonFabButton,
			IonFab,
			IonFabList,
			IonTabButton,
			IonTabBar,
			IonTabs,
		},
		data() {
			return {
				plantingId: null,
				currentTab: null,
				selectedFormatKey: "totals",

				searchicon,
				leaficon,
				mapicon,
				bookicon,
				notificationsicon,
				clipboardicon,
				addicon,
				forecasticon,
			};
		},
		created() {
			this.plantingId = +this.$route.params.plantingId;
			this.currentTab = this.$route.name;

			// Redirect if it's not in "plantings"
			if (!this.planting) this.$router.push("/");
		},
		ionViewWillEnter() {
			this.plantingId = +this.$route.params.plantingId;
			this.currentTab = this.$route.name;

			// Redirect if it's not in "plantings"
			if (!this.planting) this.$router.push("/");
		},
		methods: {
			capitalizeFirstLetter,
			customerConversion,
			viewTab(name) {
				if (name !== this.currentTab) {
					this.$router.replace({
						name: name,
						params: {
							plantingId: this.plantingId,
						},
					});
					this.currentTab = name;
				}
			},
			showNotePopup(planting) {
				showAddNote(planting);
			},
			showTaskRequestPopup(planting) {
				showRequestTask(this, null, planting);
			},
		},
		computed: {
			...mapState({
				plantings: "regions",
			}),
			...mapState([
				"permissions",
				"alerts",
				"noteCategories",
				"online",
				"user",
				"currentLocation",
				"customerUnits",
				"customerParameters",
				"notesById",
				"noteIdsByPlantingIds",
				"noteUrgencyTypesById",
				"groupedObservationSubTypes",
				"observationTypesById",
				"observationCategoriesById",
				"observationScalesById",
				"observationSubTypes",
				"taskRequestsById",
			]),
			...mapGetters(["onlineColor"]),
			planting() {
				return this.plantings.find((planting) => planting.id === this.plantingId);
			},
			plantingAlerts() {
				return this.alerts.filter((alert) => alert.regionId === this.plantingId);
			},
			isDudaCelery() {
				const isDuda = this.user.customerId === 12;
				const isCelery = this.planting?.displayCrop?.toLowerCase().includes("celery");
				return isDuda && isCelery;
			},
			plantingNotes() {
				return (this.noteIdsByPlantingIds[this.planting.id] || []).map((noteId) => this.notesById[noteId]);
			},
			pendingRequests() {
				return Object.values(this.taskRequestsById).filter(
					(x) => x.plantingId === this.plantingId && x.requestStatus === TaskRequestStatus.Pending,
				);
			},
			/** Number of task items (tasks due and pending requests) for this planting */
			tasksBadgeCount() {
				return this.tasksDue.length + this.pendingRequests.length;
			},
			tasksTabButtonStyle() {
				let selected = this.currentTab.startsWith("planting.task");
				return {
					opacity: selected ? "1" : "0.5",
					color: selected ? "var(--ion-color-contrast)" : "",
				};
			},
		},
		watch: {
			$route() {
				this.currentTab = this.$route.name;
			},
		},
	};
</script>
