import { ingestAPIObject } from "../utils/api";
import dayjs from "dayjs";
import { ITask } from "./ITask";
import { TaskStatus } from "./TaskStatus";
import { TaskType } from "./TaskType";
export class Task extends ITask {
    constructor(jsonData) {
        super();
        this.type = TaskType.General; // Fallback
        this.activity = [];
        ingestAPIObject(this, jsonData);
        this.activity = this.activity ? this.activity.map((a) => new TaskActivity(a)) : [];
        // Transform dates to datestrings only
        this.plannedDate = jsonData.plannedDate?.slice(0, 10);
        this.plannedBeginDate = jsonData.plannedBeginDate?.slice(0, 10);
        this.completedDate = jsonData.completedDate?.slice(0, 10);
        this.completedBeginDate = jsonData.completedBeginDate?.slice(0, 10);
    }
    get displayDate() {
        return (this.completedBeginDate ??
            this.completedDate ??
            this.plannedBeginDate ??
            this.plannedDate ??
            new Date().toISOString().slice(0, 10));
    }
    set displayDate(value) {
        if (this.completedDate)
            this.completedDate = value;
        else if (this.plannedDate)
            this.plannedDate = value;
    }
    get status() {
        if (this.completedDate)
            return TaskStatus.Completed;
        else if (this.activity.length > 0) {
            if (this.activity.every((a) => a.end))
                return TaskStatus.Paused;
            return TaskStatus.InProgress;
        }
        return TaskStatus.Planned;
    }
    set status(value) {
        if (!this.allowedStatuses.includes(value)) {
            throw new Error(`Invalid task status set: ${value}`);
        }
        switch (value) {
            case TaskStatus.Completed:
                this.activity.forEach((a) => (a.end ?? (a.end = new Date())));
                this.completedDate = this.displayDate;
                break;
            case TaskStatus.InProgress:
                if (this.activity.every((a) => a.end !== undefined)) {
                    this.activity.push(new TaskActivity({ start: new Date(), taskId: this.id }));
                }
                this.plannedDate = this.displayDate;
                this.completedDate = undefined;
                break;
            case TaskStatus.Paused:
                this.activity.forEach((a) => (a.end ?? (a.end = new Date())));
                this.plannedDate = this.displayDate;
                this.completedDate = undefined;
                break;
            case TaskStatus.Planned:
                this.activity = [];
                this.plannedDate = this.displayDate;
                this.completedDate = undefined;
                break;
        }
    }
    get allowedStatuses() {
        return [TaskStatus.Planned, TaskStatus.InProgress, TaskStatus.Paused, TaskStatus.Completed];
    }
    // TODO: Cache this, and recompute only at reasonable times?
    fcEvent(title, scheduleConfig) {
        const loading = !title;
        return {
            id: this.id || this.virtualId, // TODO: better id handling (refactor identifier)
            title: title,
            allDay: true,
            start: this.displayDate,
            end: this.displayEndDate,
            color: scheduleConfig?.color,
            textColor: scheduleConfig?.fontColor,
            // constraint: check planting(s) - not sure if we even want this anymore
            durationEditable: (!loading && scheduleConfig?.setPermission && scheduleConfig?.allowMultipleDays) || false,
            startEditable: (!loading && scheduleConfig?.setPermission) || false,
            extendedProps: {
                loading,
                icon: this.icon,
                iconTitle: this.iconTitle,
                type: this.type,
                status: this.status,
                task: this,
                subtitle: this.title,
            },
        };
    }
}
export class TaskActivity {
    constructor(jsonData) {
        ingestAPIObject(this, jsonData);
    }
    get durationInHours() {
        const start = dayjs(this.start);
        const end = dayjs(this.end || new Date());
        return end.diff(start, "hours", true);
    }
    static getTotalDurationInHours(activities) {
        if (activities.length === 0) {
            return 0;
        }
        return activities.reduce((acc, activity) => acc + activity.durationInHours, 0);
    }
}
// TODO: We are at the point where we should be able to merge ScheduleType and TaskType
export var ScheduleType;
(function (ScheduleType) {
    ScheduleType[ScheduleType["Weeding"] = 0] = "Weeding";
    ScheduleType[ScheduleType["Thinning"] = 1] = "Thinning";
    ScheduleType[ScheduleType["Fleecing"] = 2] = "Fleecing";
    ScheduleType[ScheduleType["BedShaping"] = 3] = "BedShaping";
    ScheduleType[ScheduleType["Planting"] = 4] = "Planting";
    ScheduleType[ScheduleType["Transplanting"] = 5] = "Transplanting";
    ScheduleType[ScheduleType["Irrigation"] = 6] = "Irrigation";
    ScheduleType[ScheduleType["SoilSample"] = 7] = "SoilSample";
    ScheduleType[ScheduleType["General"] = 8] = "General";
    ScheduleType[ScheduleType["Chemical"] = 9] = "Chemical";
})(ScheduleType || (ScheduleType = {}));
export const TaskTypeToScheduleType = {
    [TaskType.General]: ScheduleType.General,
    [TaskType.Thinning]: ScheduleType.Thinning,
    [TaskType.Weeding]: ScheduleType.Weeding,
    [TaskType.Fleecing]: ScheduleType.Fleecing,
    [TaskType.BedShaping]: ScheduleType.BedShaping,
    [TaskType.Planting]: ScheduleType.Planting,
    [TaskType.Transplanting]: ScheduleType.Transplanting,
    [TaskType.Irrigation]: ScheduleType.Irrigation,
    [TaskType.SoilSample]: ScheduleType.SoilSample,
    [TaskType.Chemical]: ScheduleType.Chemical,
};
