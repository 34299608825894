import $ from "jquery";

// Format a number as a string
// FIXME: This is **extremely** not type-safe
export function numberFormat(v: string | number, type: string, method: keyof typeof Math = "floor") {
	v = +v;

	if (!isNaN(v)) {
		switch (type) {
			// Comma separated without decimals with the last two digits thrown away (e.g. 1353 => 1300)
			case "N-2":
				return ((Math[method] as Function)(v / 100) * 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

			// Comma separated without decimals with the last digit thrown away (e.g. 1353 => 1350)
			case "N-1":
				return ((Math[method] as Function)(v / 10) * 10).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

			// Comma separated without decimals
			case "N0":
				return (Math[method] as Function)(v)
					.toString()
					.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

			// Comma separated with one decimal
			case "N1":
				return ((Math[method] as Function)(v * 10) / 10).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			// Comma separated with two decimals
			case "N2":
				return ((Math[method] as Function)(v * 100) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

			// Percent without decimals
			case "P0":
				return (Math[method] as Function)(v * 100) + "%";

			// Percent with one decimal
			case "P1":
				return (Math[method] as Function)(v * 1000) / 10 + "%";

			// Number of thousands, with a single decimal value for hundreds
			case "#.#K":
				return (Math[method] as Function)(v / 100) / 10;
		}
	}
	return v;
}

// Convert degrees to radians
function Deg2Rad(deg: number) {
	return (deg * Math.PI) / 180;
}

// Calculate the approximate distance (in miles) between two geocoordinates
export function PythagorasEquirectangular(lat1: number, lon1: number, lat2: number, lon2: number) {
	lat1 = Deg2Rad(lat1);
	lat2 = Deg2Rad(lat2);

	lon1 = Deg2Rad(lon1);
	lon2 = Deg2Rad(lon2);

	const R = 3958.8; // miles
	const x = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2);
	const y = lat2 - lat1;

	const d = Math.sqrt(x * x + y * y) * R;

	return d;
}

// Returns a date or list of dates in localized format, rendered in UTC
export function utcDate(dateStrs: string | string[]) {
	if (!(dateStrs instanceof Array)) dateStrs = [dateStrs];

	if (dateStrs instanceof Array) {
		const dates = dateStrs
			.map((dateStr) => {
				const date = AspDate(dateStr);

				if (date) return date.toLocaleDateString("default", { timeZone: "utc" });

				return "";
			})
			.filter((date) => date);

		return dates.join(", ");
	}
}

// Returns a date parsed from a backend date string, e.g. '/Date(...)/', or from a more typical date string
export function AspDate(dateStr: string) {
	const match = /Date\((\d+)\)/.exec(dateStr);

	if (match) return new Date(+match[1]);

	const date = new Date(dateStr);

	// Is it a valid date?
	if (!isNaN(date.getTime())) return date;
}

// Blend two arrays of [R, G, B] together, determining which color to favor based on a bias (0 to only use firstColor, and 1 to only use secondColor)
type Color = [number, number, number];
export function blendColors(firstColor: Color, secondColor: Color, bias: number) {
	return {
		r: Math.round(firstColor[0] * (1 - bias) + secondColor[0] * bias),
		g: Math.round(firstColor[1] * (1 - bias) + secondColor[1] * bias),
		b: Math.round(firstColor[2] * (1 - bias) + secondColor[2] * bias),
	};
}

// Pad a string with the provided character on the left until it is at least as long as the provided width
export function padLeft(field: string, width: number, char: string) {
	while (field.length < width) field = char + field;

	return field;
}

export function replaceAll(str: string, find: string, replaceWith: string, ignoreCase: boolean) {
	return str.replace(
		new RegExp(find.replace(/([/,!\\^${}[\]().*+?|<>\-&])/g, "\\$&"), ignoreCase ? "gi" : "g"),
		typeof replaceWith == "string" ? replaceWith.replace(/\$/g, "$$$$") : replaceWith,
	);
}

// We should just use lodash for this:
export function floor(number: number, decimals: number) {
	return Math.floor(number * 10 ** decimals) / 10 ** decimals;
}

export function round(number: number, decimals: number) {
	return Math.round(number * 10 ** decimals) / 10 ** decimals;
}

export function customerConversion(value: number | null, type: string, customerUnits: { [x: string]: any }) {
	const matchingUnit = customerUnits[type];

	if (matchingUnit) {
		const result = {
			nameSingular: matchingUnit.nameSingular,
			namePlural: matchingUnit.namePlural,
			nameAbbreviated: matchingUnit.nameAbbreviated,
			value: undefined as any,
		};

		if (value !== null) result.value = (value - matchingUnit.conversionShift) / matchingUnit.conversionMultiplier;

		return result;
	}
}

// Convert a value of a certain type from the customer-preferred units back to the standard units (e.g. hectares to acres)
export function customerDeconversion(value: number | null, type: string, customerUnits: { [x: string]: any }) {
	const matchingUnit = customerUnits[type];

	if (matchingUnit && value !== null) return value * matchingUnit.conversionMultiplier + matchingUnit.conversionShift;
}

export function adjustToFill(harvestAcres: any[] | null, acres: number) {
	if (!harvestAcres) return;

	let remainingSum = acres;

	harvestAcres
		.filter((x: { fillRemaining: any }) => !x.fillRemaining)
		.forEach((x: { acres: any }) => (remainingSum -= round(x.acres, 2)));

	const toFill = harvestAcres.filter((x: { fillRemaining: any }) => x.fillRemaining);

	toFill.forEach(function (item: { acres: number }, i: number) {
		const divideBy = toFill.length - i;
		item.acres = round(remainingSum / divideBy, 2);
		remainingSum -= item.acres;
	});
}

export function capitalizeFirstLetter(str: string) {
	if (str && str.length) return str.slice(0, 1).toUpperCase() + str.slice(1);

	return str;
}

// Convert a date to a string based on the provided pattern (e.g. yyyy-MM-dd)
export function dateFormat(date: Date | undefined, pattern: string, utc: boolean) {
	if (!date) return "";

	const fullYear = utc ? date.getUTCFullYear() : date.getFullYear();
	const monthNumber = (utc ? date.getUTCMonth() : date.getMonth()) + 1;
	const dateNumber = utc ? date.getUTCDate() : date.getDate();

	return pattern
		.replace("yyyy", fullYear.toString())
		.replace("yy", fullYear.toString().slice(2, 4))
		.replace("MM", padLeft(monthNumber.toString(), 2, "0"))
		.replace("M", monthNumber.toString())
		.replace("dd", padLeft(dateNumber.toString(), 2, "0"))
		.replace("d", dateNumber.toString());
}

// Get the latest harvest date from a planting, preferring statistics harvest date > updated harvest date > estimated harvest date
export function getHarvestDate(planting: any, optimized: boolean, statsName: string) {
	if (planting) {
		const dateField =
			(planting[statsName] && planting[statsName].plantingDates.estimatedHarvest) ||
			(optimized ? planting.recommendedHarvestDateRaw : null) ||
			planting.currentHarvestDateRaw ||
			planting.originalHarvestDateRaw;
		if (dateField) return new Date(dateField);
		return new Date();
	}
}

export function getHarvestDates(planting: { harvestAcres: any[] }, optimized: boolean, statsName: string) {
	if (planting) {
		if (optimized) return [getHarvestDate(planting, optimized, statsName)];

		return planting.harvestAcres?.map((x: { dateRaw: any }) => x.dateRaw) || [];
	}
}

export function getCurrentPlantingStatistic(
	planting: {
		optimizedStatistics: { statistics: { [x: string]: any } };
		statistics: { statistics: { [x: string]: any } };
		userStatistics: { statistics: { [x: string]: any } };
	},
	valueType: string,
	optimized: any,
) {
	if (planting) {
		let result;

		if (optimized) result = planting.optimizedStatistics && planting.optimizedStatistics.statistics[valueType];

		if (!result) {
			const predictedStatistic = planting.statistics && planting.statistics.statistics[valueType];
			const userStatistic = planting.userStatistics && planting.userStatistics.statistics[valueType];

			result = userStatistic || predictedStatistic;
		}

		if (result) return result.value;
	}

	return null;
}

export function getScroller(ele: string | HTMLElement | null) {
	const tagName = "MAIN";

	if (typeof ele === "string") ele = document.getElementById(ele);

	if (ele) {
		const offsetParent = ele.offsetParent;

		if (offsetParent?.shadowRoot) {
			if (offsetParent.tagName === tagName) return offsetParent;

			return Array.from(offsetParent.shadowRoot.children).find((child) => child.tagName === tagName);
		}
	}
}

/**
 * Sort an array: build the keys, sort the keys, access the array by sorted keys. Should be more efficient than
 * rebuilding the key on every single comparison. (For example, casting a string to a date object repeatedly).
 *
 * @param {Array} arr the array to sort
 * @param {function} accessor gets the value to compare from each array item
 * @param {String} order asc or desc (default)
 */
// TODO: Remove, lodash has this (sortBy)
export function sort<T>(arr: Array<T>, accessor: (item: T) => unknown, order: "asc" | "desc") {
	return arr
		.map((val, i) => ({ i: i, val: accessor(val) }))
		.sort(
			{
				asc: (a: { i: number; val: unknown }, b: { i: number; val: unknown }) =>
					(a.val as number) - (b.val as number),
				desc: (a: { i: number; val: unknown }, b: { i: number; val: unknown }) =>
					(b.val as number) - (a.val as number),
			}[order || "desc"],
		)
		.map((val) => arr[val.i]);
}

// Functions for `changeBaseMap`
function removeKeysThatStartWithUnderScores(x: { [x: string]: any }) {
	const result: { [x: string]: any } = {};

	Object.keys(x).forEach((key) => {
		if (key.indexOf("_") !== 0) {
			result[key] = x[key];

			if (result[key] instanceof Object && !Array.isArray(result[key]))
				result[key] = removeKeysThatStartWithUnderScores(result[key]);
		}
	});

	return result;
}

function excludeKeys(x: { [x: string]: any }, keys: Set<string>) {
	const result: any = {};

	Object.keys(x).forEach((key) => {
		if (!keys.has(key)) result[key] = x[key];
	});

	return result;
}

function getPaintProperties(layer: { paint: { _values: {} }; getPaintProperty: (arg0: string) => any }) {
	return Object.keys(layer.paint._values).reduce((acc: any, prop) => {
		let value;
		try {
			value = layer.getPaintProperty(prop);
		} catch {
			// FIXME: This is terrible
		}

		if (value) acc[prop] = value;

		return acc;
	}, {});
}

function getLayoutProperties(layer: { layout: { _values: {} }; getLayoutProperty: (arg0: string) => any }) {
	if (!layer.layout) return {};

	return Object.keys(layer.layout._values).reduce((acc: any, prop) => {
		let value;
		try {
			value = layer.getLayoutProperty(prop);
		} catch {
			// FIXME: This is terrible
		}

		if (value) acc[prop] = value;

		return acc;
	}, {});
}

function cleanUpSource(source: { type: string; tileSize: any; id: any }) {
	// Remove `tileSize` if `geojson`
	if (source.type === "geojson") delete source.tileSize;

	// Remove `id`
	delete source.id;

	return source;
}

export function getSourceCache(map: any, sourceName: string) {
	return (
		map.style._sourceCaches[sourceName] ||
		map.style._sourceCaches["other:" + sourceName] ||
		map.style._sourceCaches["symbol:" + sourceName]
	);
}

export function changeBaseMap(map: any, baseMap: string) {
	const $toggleInputs = $(map.getContainer()).parent().find(".layer-style-toggle input");

	$toggleInputs.attr("disabled", "");

	const baseLayerIds = new Set(map.style.stylesheet.layers.map((x: { id: any }) => x.id));

	// Determine layers that need to be re-added, as well as their order
	let layersToAdd = Object.entries(map.style._layers)
		.reduce((acc: any, kvp) => {
			const layerId = kvp[0];
			const layer: any = kvp[1];

			if (!baseLayerIds.has(layerId) && layerId.indexOf("gl-") === -1) {
				const order = layer._eventedParent._order.indexOf(layerId);

				acc.push({
					order,
					value: layer,
				});
			}

			return acc;
		}, [])
		.sort((a: any, b: any) => a.order - b.order)
		.map((x: any) => x.value);

	layersToAdd = layersToAdd.map((layer: any) => {
		const result = JSON.parse(JSON.stringify(removeKeysThatStartWithUnderScores(layer)));

		result.paint = getPaintProperties(layer);
		result.layout = getLayoutProperties(layer);

		return result;
	});

	const sourceIds = new Set(layersToAdd.map((x: any) => x.source));

	// Preserve our AWS/CloudFront sources
	Object.entries(map.style._sourceCaches).forEach((entry: any) => {
		const id = entry[0];
		const source = entry[1]._source;

		if (source.tiles && source.tiles.some((tile: string | string[]) => tile.indexOf("amazonaws.com") !== -1))
			sourceIds.add(id);
		else if (source._data && typeof source._data === "string" && source._data.indexOf("cloudfront.net") !== -1)
			sourceIds.add(id);
	});

	// Determine sources that need to be re-added (and their data)
	const sources = Array.from(sourceIds).map((x: any) => {
		return {
			source: removeKeysThatStartWithUnderScores(
				excludeKeys(
					getSourceCache(map, x)._source,
					new Set([
						"actor",
						"isTileClipped",
						"load",
						"map",
						"promoteId",
						"reparseOverscaled",
						"serialized",
						"workerOptions",
						"serialize",
						"tileBounds",
						"dispatcher",
						"roundZoom",
						"minzoom",
						"maxzoom",
					]),
				),
			),
			data: getSourceCache(map, x)._source._data,
			options: getSourceCache(map, x)._source._options,
		};
	});

	// TODO: Dynamically check for and re-add image data?
	const imageData = ["marker-hover", "photo", "marker", "photo-hover"].map((x) => {
		return { name: x, data: map.style.imageManager.images[x] };
	});

	// Re-load everything when the base map changes
	map.once("styledata", () => {
		// Re-load imagery
		imageData.forEach((img) => img.data && map.addImage(img.name, img.data.data));

		// Re-add sources
		sources.forEach((source) => {
			const sourceToAdd: any = cleanUpSource(JSON.parse(JSON.stringify(source.source)));

			Object.entries(source.options).forEach((option: any) => (sourceToAdd[option[0]] = option[1]));

			if (source.data) sourceToAdd.data = source.data;

			if (!map.getSource(source.source.id)) map.addSource(source.source.id, sourceToAdd);
		});

		// Re-add layers
		layersToAdd.forEach((layer: any) => map.addLayer(layer));

		setTimeout(() => $toggleInputs.attr("disabled", null), 200);
	});

	// Change the "base map"
	map.setStyle("mapbox://styles/mapbox/" + baseMap);
}

// Time: ((n - 1) * t) + (n * getCurrentPosition())
function getCurrentPositionBurst(
	n: number,
	t: number | undefined,
	callback: { (): void; (arg0: GeolocationPosition): void },
	intermediateCallback: (arg0: GeolocationPosition) => void,
) {
	navigator.geolocation.getCurrentPosition(
		// Success
		(e) => {
			if (intermediateCallback) intermediateCallback(e);

			// Use the position from the nth call
			if (--n <= 0) callback(e);
			// Wait `t` ms between calls
			else
				setTimeout(() => {
					getCurrentPositionBurst(n, t, callback, intermediateCallback);
				}, t);
		},
		// Error
		() => {},
		// Options
		{
			enableHighAccuracy: true,
			maximumAge: 0,
		},
	);
}

export function queryCurrentLocation(
	callback: {
		(location: any): Promise<any>;
		(location: any): Promise<any>;
		(location: any): void;
		(arg0: any): void;
	},
	intermediateCallback: {
		(location: any): Promise<any>;
		(location: any): Promise<any>;
		(location: any): void;
		(arg0: GeolocationPosition): void;
	},
) {
	let watches = 0;
	let location: GeolocationPosition | null = null;

	getCurrentPositionBurst(
		2,
		500,
		() => {
			// Call the watcher
			const watcher = navigator.geolocation.watchPosition(
				// Success
				(newLocation) => {
					// Only look at locations with improving accuracy
					if (location && location.coords.accuracy <= newLocation.coords.accuracy) return;

					location = newLocation;
					const currentWatch = ++watches;

					// If the location hasn't been updated after 2 seconds, commit to the location
					setTimeout(() => {
						if (currentWatch === watches) {
							navigator.geolocation.clearWatch(watcher);

							if (callback) callback(location);
						}
					}, 2.5 * 1000);

					if (intermediateCallback) intermediateCallback(location);
				},
				// Error
				() => {},
				// Options
				{
					enableHighAccuracy: true,
					maximumAge: 0,
				},
			);
		},
		intermediateCallback,
	);
}

/* Builds the lot UID from a Planting's location */
export function getLotUIDFromPlanting(planting: any) {
	if (!planting) return undefined;

	return (planting.lot || "") + "@" + (planting.ranch?.id || "") + ">" + (planting.primaryOrganization?.id || "");
}

/* Takes in date string, returns date from *only* the date portion of the string (no timezone) */
export function getCleansedDate(date: string | Date | undefined) {
	if (date instanceof Date) return date;
	if (!date || typeof date !== "string") return undefined;

	const parts = date.substr(0, 10).split("-");

	return new Date(+parts[0], +parts[1] - 1, +parts[2]); // js counts months starting from 0 -> January
}

// Prepares a date with no time/timezone for Seedgreen
export function toMidnightString(date: Date, isUTC = false) {
	return dateFormat(date, "yyyy-MM-ddT00:00:00Z", isUTC);
}

// Get a date in yyyy-mm-dd format
export function getDateStr(d: string | Date | null) {
	if (!d) return null;

	if (typeof d === "string") {
		if (/^\d{4}-\d{2}-\d{2}$/.test(d)) return d; // Already in the format we want

		d = new Date(d);
	}

	return dateFormat(d, "yyyy-MM-dd", false);
}

/* Convert between a compass vector angle in degrees, and closest bed direction string */
export function degToBedDirection(angle: number) {
	angle = angle % 360;
	angle += 45;

	if (angle < 90) return "S->N";
	if (angle < 180) return "W->E";
	if (angle < 270) return "N->S";
	return "E->W";
}
export function bedDirectionToDeg(direction: string) {
	return {
		"S->N": 0,
		"W->E": 90,
		"N->S": 180,
		"E->W": 270,
	}[direction];
}

// Takes a date string and returns a UTC date string of M/d/yyyy
export function strDateClean(date?: string) {
	if (!date) return "";

	return dateFormat(getCleansedDate(date), "M/d/yyyy", true);
}
