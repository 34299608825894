import { ingestAPIObject } from "../utils/api";
import { TaskType } from "./TaskType";
export class TaskIdentifier {
    // Gets the id that is used on the backend for this task, because some tasks may share the same id if they have different types
    get rawId() {
        if (typeof this.id === "string")
            return parseInt(this.id.replace(/\D/g, ""));
        return this.id;
    }
    constructor(data) {
        this.taskType = TaskType.General;
        ingestAPIObject(this, data);
    }
    get idString() {
        return this.id?.toString();
    }
}
