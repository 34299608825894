import { TaskIdentifier } from "./TaskIdentifier";
import { TaskStatus } from "./TaskStatus";
import { VirtualTaskIdentifier } from "./VirtualTaskIdentifier";
export class ITask {
    constructor() { }
    /**
     * Checks the identifiers of two tasks to see if they are the same.
     *
     * Will check id, virtualId, and type.
     */
    static isSame(a, b) {
        if (a.id !== undefined && b.id !== undefined)
            return a.id === b.id && a.type === b.type;
        else if (a.virtualId || b.virtualId)
            return a.virtualId === b.virtualId && a.type === b.type;
        else {
            console.error("Rogue task(s) found, one of these doesn't have an id or virtualId", a, b);
            throw Error("Tried to compare two tasks when one does not have a valid id or virtualId");
        }
    }
    isSame(other) {
        return ITask.isSame(this, other);
    }
    /**
     * A task supercedes another task if it is more recently modified. A task with a real id will always
     * supercede a virtual task.
     */
    static supercedes(a, b) {
        if (a.virtualId === b.virtualId && a.type === b.type) {
            if (a.id !== undefined && b.id === undefined)
                return true;
            return (a.lastUpdated ?? 0) > (b.lastUpdated ?? 0);
        }
        return false;
    }
    supercedes(other) {
        return ITask.supercedes(this, other);
    }
    /**
     * A task is virtual if it has not yet been saved to the database, and therefore has no id.
     */
    get isVirtual() {
        return this.id === undefined;
    }
    set isVirtual(_) { }
    // TODO: Deprecated: use isSame and supercedes instead
    get identifier() {
        if (this.id)
            return new TaskIdentifier({ id: this.id, taskType: this.type });
        else if (this.virtualId)
            return new VirtualTaskIdentifier({
                virtualId: this.virtualId,
                taskType: this.type,
                plantingId: this.location?.plantingIds?.[0],
                irrigationBlockId: this.location?.irrigationBlockId,
            });
    }
    /**
     * The weakest identifier of this task. Useful to quickly compare tasks, but may break if the
     * virtualId gets deleted off the task.
     */
    get weakIdentifier() {
        return this.virtualId ?? this.id;
    }
    get isAutoScheduled() {
        return this.isVirtual;
    }
    get icon() {
        if (this.isAutoScheduled)
            return "fa-solid fa-calendar-clock";
        return this.status ? TaskStatus.data(this.status).icon : undefined;
    }
    get iconTitle() {
        if (this.isAutoScheduled)
            return "Auto-scheduled";
        return this.status ? TaskStatus.data(this.status).label : undefined;
    }
    // TODO: Multi-day events
    get displayEndDate() {
        return this.displayDate;
    }
}
